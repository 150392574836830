import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "fileName", "input" ]

  connect() {
    this.updateName()
  }

  updateName(event) {
    if(this.inputTarget.files.length > 0) {
      this.fileNameTarget.textContent = this.inputTarget.files[0].name
    }
  }

}
