import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["conceptTypeSelect", "conceptSelect", "manualInputsCheckbox", "taxableInput", "exemptInput", "totalInput" ]

  disableInputs(event){
    if (!this.manualInputsCheckboxTarget.checked){
      this.taxableInputTarget.disabled = true
      this.exemptInputTarget.disabled = true
      this.totalInputTarget.disabled = true
      this.taxableInputTarget.value = ""
      this.exemptInputTarget.value = ""
      this.totalInputTarget.value = ""
    } else {
      this.conceptTypeSelected(event)
    }
  }

  conceptTypeSelected(event){
    let conceptTypeSelected  = this.conceptTypeSelectTarget.value
    if (conceptTypeSelected === "deduction"){
      this.taxableInputTarget.disabled = true
      this.exemptInputTarget.disabled = true
      this.totalInputTarget.disabled = false

    } else if (conceptTypeSelected === "perception"){
      this.taxableInputTarget.disabled = false
      this.exemptInputTarget.disabled = false
      this.totalInputTarget.disabled = true

    } else if (conceptTypeSelected === "other_payment"){
      this.taxableInputTarget.disabled = true
      this.exemptInputTarget.disabled = true
      this.totalInputTarget.disabled = false

    } else {
      this.taxableInputTarget.disabled = true
      this.exemptInputTarget.disabled = true
      this.totalInputTarget.disabled = true
    }
  }

  conceptSelected(event){
    const optionSelected = this.conceptSelectTarget.options[this.conceptSelectTarget.selectedIndex]
    if (typeof optionSelected != "undefined"){
      let hasCalculator = optionSelected.getAttribute("data-has-calculator") || false
      if (hasCalculator == "0" || hasCalculator == "false"){
        this.manualInputsCheckboxTarget.checked = true
        this.manualInputsCheckboxTarget.disabled = true
      }else{
        this.manualInputsCheckboxTarget.checked = false
        this.manualInputsCheckboxTarget.disabled = false
      }
      this.disableInputs(event)
    }
  }

  cleanInputs(){
    this.taxableInputTarget.value = ""
    this.exemptInputTarget.value = ""
    this.totalInputTarget.value = ""
  }
}
