import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["element"]
  

  connect() {
    console.log('Alert controller connected')
  }

  close(event){
    this.element.classList.add('hidden', 'transition', 'transition-opacity', 'duration-1000');
    setTimeout(() => this.elementTarget.remove(), 1000)
  }

}