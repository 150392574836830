import { ApplicationController, useDebounce } from 'stimulus-use'
export default class extends ApplicationController {
  static values = {
    inputsClass: String
  }
  static debounces = [
    {
      name: 'debouncedRequestSubmit',
      wait: 300
    }
  ]

  connect() {
    useDebounce(this)
  }

  disableInputs() {
    if (this.hasInputsClassValue) {
      let inputs = document.querySelectorAll("." + this.inputsClassValue)
      inputs.forEach(input => input.disabled = true)
    }
  }

  debouncedRequestSubmit(event) {
    event.target.form.requestSubmit()
  }
}
