import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    url: String,
    method: String,
  }

  connect() {
    Rails.ajax({
      url: this.urlValue || "/",
      type: (this.methodValue || "GET").toUpperCase()
    })
  }
}
