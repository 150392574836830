import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "element" ]

  connect() {
    let firstErrorElement = document.querySelectorAll(".error-message")[0]
    if (this.hasElementTarget && this.elementTarget === firstErrorElement){
      this.elementTarget.scrollIntoView({behavior: "smooth", block: "center"});
    }
  }
}
