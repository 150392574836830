import CheckboxSelectAll from "stimulus-checkbox-select-all"
import Rails from "@rails/ujs"

export default class extends CheckboxSelectAll {
  authorize(event){
    this.bulk_action(event, "authorize")
  }

  deauthorize(event){
    this.bulk_action(event, "deauthorize")
  }

  certify(event){
    this.bulk_action(event, null)
  }

  bulk_action(event, action) {
    event.preventDefault()

    let data = new FormData()
    if (this.checked.length == this.checkboxTargets.length) {
      data.append("all", true)
    } else {
      this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))
    }

    if (action) { data.append("action_name", action) }
    let path = event.params.url

    Rails.ajax({
      url: path,
      type: "PUT",
      data: data
    })
  }
}
