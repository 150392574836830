// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbo from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "chartkick/chart.js"
import "./custom_confirmation.js"
import "stylesheets/application.scss"

Rails.start();
ActiveStorage.start();

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import CheckboxSelectAll from "stimulus-checkbox-select-all"
import NestedForm from 'stimulus-rails-nested-form'
import Reveal from 'stimulus-reveal-controller'


const images = require.context('../images', true)
const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
)

import Popover from 'stimulus-popover'
import Dropdown from 'stimulus-dropdown'

application.register('dropdown', Dropdown)
// TODO: Fix "tailwindcss-stimulus-components" package and remove components from /app/javascript/controllers/
// import { Alert, Autosave, Dropdown, Modal, Tabs, Toggle, Slideover } from "tailwindcss-stimulus-components"

// application.register('modal', Modal)
// application.register('tabs', Tabs)
// application.register('toggle', Toggle)
// application.register('slideover', Slideover)
application.register('popover', Popover)
application.register("checkbox-select-all", CheckboxSelectAll)
application.register('nested-form', NestedForm)
application.register('reveal', Reveal)

